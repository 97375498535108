import { ReactElement } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Views
import Poa from '../../../views/Documents/Poa';
import AttorneysInFact from '../../../views/Documents/Poa/AttorneysInFact/';
import PoaPowers from '../../../views/Documents/Poa/PoaPowers';
import PoaReview from '../../../views/Documents/Poa/PoaReview';
import PoaSpecialInstructions from '../../../views/Documents/Poa/PoaSpecialInstructions';
import PoaIntro from '../../../views/Documents/Poa/PowerIntro';

// Hooks
import useAuth from '../../../hooks/useAuth';
import usePoaSectionStatus from '../../../hooks/usePoaSectionStatus';

// Routes
import {
  dashboard as dashboardRoute,
  poa_intro,
  poa,
  poa_attorney_in_fact,
  poa_powers,
  poa_special_instructions,
  poa_review,
  login as loginRoute,
  checkout,
} from '../../../../core/routes';
import { useSelector } from '../../../store';

// Redux
import productsSelector from '../../../store/selectors/productsSelector';
import useUserAttributes from '../../../hooks/useUserAttributes';
import entitlementsSelector from '../../../store/selectors/entitlementsSelector';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

// Helpers
import { UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE } from '../../../../core/routerConstants';


export default function PoaRouter(): ReactElement{
  const {
    documents: { poa: { poaDocumentViewed: hasViewedPOADocument } },
  } = useUserAttributes();
  const { pro } = useSelector( productsSelector );
  const { isGuestUser } = useAuth();
  const location = useLocation();
  const { availableDocuments } = useSelector( entitlementsSelector );
  const isDocumentSupported = availableDocuments.includes( DOCUMENTS.power_of_attorney );
  const { canCompletePoa } = usePoaSectionStatus();

  return (
    <Switch>
      {/* @TODO: automate this for all docs */}
      {!isDocumentSupported && <Redirect to={ UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE }/> }
      {isGuestUser && <Redirect to={{ pathname: loginRoute.path, search: generateQueryString({ next: location.pathname, background: dashboardRoute.get() }) }} />}

      <Route path={ poa.path }>
        <>
          <Route exact path={ poa.path }>
            {hasViewedPOADocument && canCompletePoa
              ? <Redirect to={ poa_review.path } />
              : <Poa />
            }
          </Route>
          <Route exact path={ poa_intro.path } component={ PoaIntro } />
          <Route exact path={ poa_attorney_in_fact.path } component={ AttorneysInFact }/>
          <Route exact path={ poa_powers.path } component={ PoaPowers }/>
          <Route exact path={ poa_special_instructions.path } component={ PoaSpecialInstructions }/>

          <Route path={ poa_review.path }>
            <Switch>
              {!canCompletePoa && <Redirect to={ poa.get() } /> }
              {!pro.hasPurchased && <Redirect to={{ pathname: checkout.path, search: generateQueryString({ document: DOCUMENTS.power_of_attorney }) }} /> }
              <PoaReview />
            </Switch>
          </Route>
        </>
      </Route>
    </Switch>
  );
}
