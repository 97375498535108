import { ReactElement, ReactNode } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Hooks
import useAuth from '../../hooks/useAuth';

// Routes
import {
  dashboard as dashboardRoute,
  login as loginRoute,
  checkout as checkoutRoute,
} from '../../../core/routes';
import { useSelector } from '../../store';

// Redux
import entitlementsSelector from '../../store/selectors/entitlementsSelector';

// Utils
import { generateQueryString } from '../../utils/urlUtils';

// Helpers
import { UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE } from '../../../core/routerConstants';
import { ProductDocument } from '../../types/Entitlements/EntitlementInterface';


type GuardedRouterAttributes = {
    hasViewedDocument: boolean;
    hasViewedIntro?: boolean;
    canCompleteDocument: boolean;
    hasPurchasedDocument: boolean;
}

type GuardedRouteConfig = {
    path: string;
    component: ()=> ReactElement;
}

export default function DocumentRouter({
  document,
  attributes,
  intro,
  overview,
  review,
  children,
}: {
    document: ProductDocument;
    attributes: GuardedRouterAttributes;
    intro: GuardedRouteConfig;
    overview: GuardedRouteConfig;
    review: GuardedRouteConfig;
    children: ReactNode;
}): ReactElement{
  const { isGuestUser } = useAuth();
  const { pathname } = useLocation();
  const { availableDocuments } = useSelector( entitlementsSelector );
  const isDocumentSupported = availableDocuments.includes( document );

  return (
    <Switch>
      {!isDocumentSupported && <Redirect to={ UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE }/> }
      <Route exact path={ overview.path }>
        <Switch>
          {attributes.hasViewedDocument && attributes.canCompleteDocument && <Redirect to={ review.path }/>}
          {!attributes.hasViewedIntro && <Redirect to={ intro.path } />}
          <overview.component />
        </Switch>
      </Route>
      <Route exact { ...intro } />

      {isGuestUser && <Redirect to={{ pathname: loginRoute.path, search: generateQueryString({ next: pathname, background: dashboardRoute.get() }) }} />}

      {children}

      <Route path={ review.path }>
        <Switch>
          {!attributes.canCompleteDocument && <Redirect to={ overview.path } /> }
          {!attributes.hasPurchasedDocument && <Redirect to={{ pathname: checkoutRoute.path, search: generateQueryString({ document }) }} /> }
          <review.component />
        </Switch>
      </Route>
    </Switch>
  );
}
